import { SpeakerCalendar } from "../../components/SpeakerCalendar";
var speaker_slot_template = require("./assign_speaker_slot.twig");
let speaker_selection_container_template = require("../meetingrequest/speaker_selection_container.twig");

export class AssignSpeakerSlot {
  constructor(meeting_data, speaker, topic_modules, index) {
    this.meeting_data = meeting_data;
    this.speakerData = {};
    let speaker_preference =
      app.appData.layout.meeting_request.speaker_preference || "normal";
    let speakerModules = Object.values(topic_modules).find(
      (item) => item.topic_id === speaker.topic_id
    );

    this.dom = $(
      twig({ data: speaker_slot_template }).render({
        ...speaker,
        modules: speakerModules.meeting_modules,
      })
    );

    this.dom.find(".assign_speaker_selections_container").append(
      $(
        twig({ data: speaker_selection_container_template }).render({
          priority: speaker_preference === "normal" ? "Preferred" : "",
          speaker_preference,
          required: "*",
          patient: false,
          show_speaker_bio:
            app.appData.layout.meeting_request.speaker_bio_template,
          show_speaker_travel_contact:
            app.appData.layout.meeting_request.show_speaker_travel_contact,
          labels: app.appData.labels,
          speaker_loc: 0,
          show_non_local_speaker_rationale: false,
          show_speaker_detail_button: false,
          modal: true,
        })
      )
    );

    this.dom.on("change", ".speaker_dd", (event) => {
      let speaker_dd = $(event.target);
      let speaker_id = $(event.target).val();
      this.checkSpeakerValidations(speaker_id, speaker_dd);
      let preselectedValue = speaker_dd.data("preselected");
  
      if (speaker_id && speaker_id !== preselectedValue) {
          speaker_dd.removeClass("is-invalid");
      }
      this.dom.trigger("speakerUpdated");
    });

    this.topic_id = this.dom.find(".topic_id").first();
    this.drawSpeakerDDs(speaker.topic_id, index);

    this.dom.on("click", ".speaker_calendar_button", (event) => {
      event.preventDefault();
      let speaker_id = $(event.target)
        .closest(".form-row")
        .find(".speaker_dd")
        .val();
      new SpeakerCalendar(speaker_id);
    });
  }

  checkSpeakerValidations(speaker_id, speaker_dd) {
    if (speaker_id !== "") {
      this.getSpeakerDetails(speaker_id).then((data) => {
        this.speakerData[speaker_id] = data;
        this.fillSpeakerAddressInfo(data, speaker_dd);
        this.toggleRationale("speaker_dd", speaker_dd);
        this.localCapValidation(speaker_dd, data);
        this.thresholdValidation(speaker_dd, data);
        this.storeSpeakerConfirmedTimestamp(speaker_dd);
        this.calculateTravelDistance();
      });
    }
  }

  getSpeakerDetails(speaker_id) {
    let { project_id, meeting_date, program_type_id } =
      this.meeting_data.logistics_info;
    return $.ajax({
      url: `api/meetingrequest/speakers/${speaker_id}`,
      data: {
        project_id: project_id,
        meeting_date: moment(meeting_date).format("MM/DD/YYYY"),
        brand_id: app.appData.projects[project_id].brand_id,
        program_type_id: program_type_id,
        include_program_group_type_id:
          app.appData.layout.meeting_request
            .speaker_brand_program_group_threshold_check,
      },
      method: "get",
      async: false,
    });
  }

  drawSpeakerDDs(topic_id, spkr_index) {
    this.dom.find(".speaker_dd").hide();

    let speakers = this.getSpeakers(topic_id) || [];
    let project_id = this.meeting_data.logistics_info.project_id;
    let preselectedSpeaker =
      this.meeting_data.speaker_info?.[spkr_index] || null;

    if (
      preselectedSpeaker &&
      !speakers.some((s) => s.speaker_id === preselectedSpeaker.speaker_id)
    ) {
      speakers.push(preselectedSpeaker);
    }

    if (speakers.length > 0) {
      this.dom.find(".speaker_dd").each((_, dd) => {
        let $dd = $(dd);
        this.drawSpeakerDD(
          $dd,
          speakers,
          project_id,
          false,
          preselectedSpeaker?.speaker_id
        );
      });
    }

    this.dom.find(".speaker_dd").show();
  }

  getSpeakers(topic_id) {
    let { project_id, meeting_date, program_type_id } =
      this.meeting_data.logistics_info;
    let { event_type_id, brand_id } = app.appData.projects[project_id];

    let speakers = [];
    $.ajax({
      url: "api/meetingrequest/speakers",
      method: "get",
      async: false,
      data: {
        meeting_date: moment(meeting_date).format("MM/DD/YYYY"),
        project_id,
        brand_id,
        program_type_id,
        event_type_id,
        topic_id,
      },
      success: (data) => {
        speakers = data;
      },
    });

    return speakers;
  }

  drawSpeakerDD(
    speaker_dd,
    speakers,
    project_id,
    patients = false,
    preselectedSpeakerId = null
  ) {
    let brand_id = app.appData.projects[project_id].brand_id;
    let local_rdt_size = app.appData.brands[brand_id]?.local_speaker_rdt_size;
    let show_by_category =
      app.appData.layout.meeting_request.show_speakers_by_consultant_category;

    speaker_dd
      .empty()
      .append($("<option>", { text: "Select a Speaker", value: "" }));

    if (!patients && local_rdt_size > 0) {
      this.addRegionalOptions(
        speaker_dd,
        speakers,
        local_rdt_size,
        preselectedSpeakerId
      );
    } else if (show_by_category) {
      this.addCategoryOptions(speaker_dd, speakers, preselectedSpeakerId);
    } else {
      this.addStandardOptions(
        speaker_dd,
        speakers,
        patients,
        preselectedSpeakerId
      );
    }
  }

  addRegionalOptions(
    speaker_dd,
    speakers,
    local_rdt_size,
    preselectedSpeakerId
  ) {
    let localOptgroup = $('<optgroup label="Regional">');
    let nonLocalOptgroup = $('<optgroup label="Non-Local">');

    speakers.forEach((speaker) => {
      let isLocal = speaker.rdt
        ?.split(",")
        .some(
          (value) =>
            value.substring(0, local_rdt_size) ===
            app.appData.curPosition.rdt.substring(0, local_rdt_size)
        );

      let option = this.createSpeakerOption(speaker, preselectedSpeakerId);
      isLocal ? localOptgroup.append(option) : nonLocalOptgroup.append(option);
    });

    speaker_dd.append(
      localOptgroup.children().length
        ? localOptgroup
        : $("<option>", { text: "none", disabled: true })
    );
    speaker_dd.append(
      nonLocalOptgroup.children().length
        ? nonLocalOptgroup
        : $("<option>", { text: "none", disabled: true })
    );
  }

  addCategoryOptions(speaker_dd, speakers, preselectedSpeakerId) {
    let categories = {};
    let otherOptgroup = $('<optgroup label="OTHER">');

    speakers.forEach((speaker) => {
      let category = speaker.consultant_category_type || "OTHER";
      let option = this.createSpeakerOption(speaker, preselectedSpeakerId);

      if (!categories[category])
        categories[category] = $(`<optgroup label="${category}">`);
      categories[category].append(option);
    });

    Object.values(categories).forEach((optgroup) =>
      speaker_dd.append(optgroup)
    );
    if (otherOptgroup.children().length) speaker_dd.append(otherOptgroup);
  }

  addStandardOptions(speaker_dd, speakers, patients, preselectedSpeakerId) {
    speakers.forEach((speaker) => {
      if (
        (patients && speaker.consultant_code !== "patient") ||
        (!patients && speaker.consultant_code === "patient")
      ) {
        return;
      }

      let classDanger = "";
      let suffix = "";
      if (
        app.appData.layout.meeting_request
          .speaker_brand_program_group_threshold_check &&
        (speaker.speaker_status_key === "apprv_cap" ||
          this.isThresholdMet(speaker))
      ) {
        classDanger = "text-danger";
      }
      if (speaker.topic_cap_max_met && speaker.consultant_code === "hcp") {
        suffix = " - Topic Capped";
        classDanger = "text-danger";
      }

      speaker_dd.append(
        $("<option>", {
          class: classDanger,
          value: speaker.speaker_id,
          text: `${speaker.name} - ${speaker.city}, ${speaker.state}${suffix}`,
          selected: preselectedSpeakerId === speaker.speaker_id,
          disabled: preselectedSpeakerId === speaker.speaker_id,
        })
      );
    });
  }

  createSpeakerOption(speaker, preselectedSpeakerId) {
    return $("<option>", {
      value: speaker.speaker_id,
      text: `${speaker.name} - ${speaker.city}, ${speaker.state}`,
      selected: preselectedSpeakerId === speaker.speaker_id,
      disabled: preselectedSpeakerId === speaker.speaker_id,
    });
  }

  isThresholdMet(speaker) {
    return speaker.speaker_status_key === "apprv_cap";
  }

  toggleRationale(speaker_type, speaker_dd) {
    if (this.show_non_local_speaker_rationale) {
      let selectElement = "";
      if (speaker_type === "speaker_dd") {
        selectElement = speaker_dd
          .closest(".speaker_selection_container")
          .find(".speaker_dd");
      } else {
        selectElement = speaker_dd
          .closest(".patient_speaker_selections_container")
          .find(".patient_speaker_dd");
      }
      if (selectElement.find(":selected").length > 0) {
        let optgroup = selectElement.find(":selected").parent();
        if (optgroup.attr("label") === "Non-Local") {
          speaker_dd
            .closest(".speaker_selection_container")
            .find(".rationale_dd")
            .addClass("required is-invalid");
          speaker_dd
            .closest(".speaker_selection_container")
            .find(".rationale-dropdown")
            .show();
        } else {
          speaker_dd
            .closest(".speaker_selection_container")
            .find(".rationale_dd")
            .removeClass("required is-invalid");
          speaker_dd
            .closest(".speaker_selection_container")
            .find(".rationale-dropdown")
            .hide();
        }
        speaker_dd
          .closest(".speaker_selection_container")
          .find(".rationale-dropdown")
          .val("");
      }
    }
  }

  thresholdValidation(speaker_dd = "", speaker_data = {}) {
    if (speaker_dd.val() !== "" && $("#hub_meeting_id").val() === "") {
      this.dom.find(".warning-threshold-reached").addClass("d-none");
      if (this.isThresholdMet(speaker_data)) {
        this.dom.find(".warning-threshold-reached").removeClass("d-none");
        speaker_dd.val("").trigger("change");
      }
    }
  }

  localCapValidation(speaker_dd = "", speaker_data = {}) {
    this.dom.find(".warning-local-cap-reached").addClass("d-none");

    if (speaker_dd.length && Object.keys(speaker_data).length) {
      speaker_dd.removeClass("speaker_local_cap_maxed");

      if (
        $("#hub_meeting_id").val() === "" &&
        (speaker_data.speaker_status_key === "apprv_cap" ||
          (speaker_data.local_cap.exclude_capped_speaker &&
            speaker_data.local_cap.localcap > 0 &&
            speaker_data.local_cap.count >= speaker_data.local_cap.localcap))
      ) {
        this.dom.find(".warning-local-cap-reached").removeClass("d-none");
        speaker_dd.addClass("speaker_local_cap_maxed");
      }
    }

    this.dom.find(".speaker_dd").each((i, el) => {
      if ($(el).hasClass("speaker_local_cap_maxed")) {
        this.dom.find(".warning-local-cap-reached").removeClass("d-none");
        return false;
      }
    });
  }

  fillSpeakerAddressInfo(data, speaker_dd = "") {
    let address =
      data.address1 +
      " " +
      data.city +
      ", " +
      data.state +
      " " +
      data.zip +
      " " +
      data.country;
    speaker_dd.closest(".form-group").find(".rec_address").val(address);
  }

  storeSpeakerConfirmedTimestamp(speaker_dd = "") {
    let formattedTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');
    speaker_dd.closest(".form-group").find(".speaker_confirmed").val(formattedTimestamp);
}

  calculateTravelDistance() {
    let venue = this.meeting_data.venue_info;

    // Validate venue data before proceeding
    if (
      ![
        venue.venue_address,
        venue.venue_city,
        venue.venue_state,
        venue.venue_zip,
      ].every((val) => val.trim() !== "")
    ) {
      console.error("Invalid venue information");
      return;
    }

    let venue_address = `${venue.venue_address} ${venue.venue_city}, ${venue.venue_state} ${venue.venue_zip} ${venue.venue_country}`;

    $(".rec_address").each((i, el) => {
      let speaker_addy = $(el).val();
      if (!speaker_addy) {
        return; 
      }

      this.getTravelDistance(speaker_addy, venue_address, (data) => {
        if (!data.routes.length) {
          console.error("No routes found");
          return;
        }

        let route = app.appData.configs.GET_SHORTEST_ROUTE
          ? this.getShortestRoute(data.routes)
          : this.getLongestRoute(data.routes);

        let distance_mi = (data.routes[route].legs[0].distance.value * 0.000621371192).toFixed(2);
        $(el).closest(".form-group").find(".rec_mtm").val(distance_mi);
      });
    });
  }


  getData() {
    let mtm_details = [];
    this.dom.find(".rec_mtm").each((i, e) => {
      mtm_details.push($(e).val());
    });

    let primary_speaker_id = this.dom.find(".speaker_dd:first").val();
    let local_cap_upper_bound = 0;
    let local_cap_count = 0;
    let speaker_local_cap = 0;
    let speaker_contract = {};

    if (
      typeof this.speakerData[primary_speaker_id] !== "undefined" &&
      !$.isEmptyObject(this.speakerData[primary_speaker_id])
    ) {
      local_cap_upper_bound = parseInt(
        this.speakerData[primary_speaker_id].local_cap.localcap,
        10
      );
      local_cap_count = parseInt(
        this.speakerData[primary_speaker_id].local_cap.count,
        10
      );

      speaker_local_cap = !(
        local_cap_upper_bound === 0 || local_cap_count < local_cap_upper_bound
      )
        ? 1
        : 0;
      speaker_contract = this.speakerData[primary_speaker_id].contracted;
    }

    return {
      topic_id: this.dom.find(".topic_id").val(),
      sub_topic_id: this.dom.find(".sub_topic_id").val(),
      speaker_id: this.dom.find(".speaker_dd:first").val(),
      speaker_local_cap: speaker_local_cap,
      speaker_name: this.dom.find(".speaker_dd:first :selected").text(),
      contact_made: this.dom.find(".contact_made").is(":checked") ? 1 : 0,
      address: this.dom.find(".rec_address").val(),
      mtm_details: mtm_details,
      contracted: speaker_contract,
      speaker_confirmed: this.dom.find(".speaker_confirmed").val(),
    };
  }

  getTravelDistance(origin, dest, callback) {
    let directionsService = new google.maps.DirectionsService();
    let dir_request = {
      origin: origin,
      destination: dest,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      provideRouteAlternatives: false,
      region: "US",
    };

    directionsService.route(dir_request, (response, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        callback(response);
      }
    });
  }

  getShortestRoute(routes) {
    let minDistance = routes[0].legs[0].distance.value;
    let routeIndex = 0;

    for(let i=0; i<routes.length; i++)
    {
        if(routes[i].legs[0].distance.value <= minDistance)
        {
            minDistance = routes[i].legs[0].distance.value;
            routeIndex = i;
        }
    }

    return routeIndex;
  }

  getLongestRoute(routes) {
    let maxDuration = 0;
    let routeIndex = 0;

    for(let i=0; i<routes.length; i++) {
        if(routes[i].legs[0].duration.value > maxDuration) {
            maxDuration = routes[i].legs[0].duration.value;
            routeIndex = i;
        }
    }
    return routeIndex;
  }
}
