import RepEvaluation from "@appdev/evaluations-ui/evaluations/rep";
import template from './program_evaluation.twig';

export default class ProgramEvaluation {
    constructor(meetingData) {
        this.dom = $(twig({ data: template }).render({ topics: meetingData.speaker_info }))

        let mode = meetingData.eval.mode == 'eval_view' ? 'view' : 'default';
        this.evaluation = new RepEvaluation(mode);
        this.evaluation.fetch_evaluation_html(this.dom.find('#evals_container'), meetingData.logistics_info.meeting_id);
    }

    ready() {

        this.modal = new bootstrap.Modal(this.dom);
        this.modal.show();

        $(this.dom).on("submit", "form[name=evaluation]", (event) => {
          event.preventDefault();
          let missing_required = this.evaluation.highlight_missing_required();
          if (missing_required) {
            this.dom.find(".evaluation_success_message").hide();
            this.dom.find(".missing_required_message").show();
            return;
          } else {
            this.dom.find(".evaluation_success_message").show();
            this.dom.find(".missing_required_message").hide();
            this.modal.hide();
          }
        });
    }
}
