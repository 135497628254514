import {SpeakerCalendar} from "../../components/SpeakerCalendar";
import {SpeakerMeetings} from "../../components/SpeakerMeetings";

var speaker_slot_template = require('./speaker_slot.twig');

export class SpeakerSlot
{
    constructor(speaker, topic_modules) {
        this.meeting_id = window.location.toString().split("/").pop();
        let productNames = [];
        let topic_duration = (typeof app.appData.opco_config.sb.meeting_request.show_topic_duration == "undefined") ? false : app.appData.opco_config.sb.meeting_request.show_topic_duration;
        let is_modular_topic_enabled = (typeof app.appData.configs.ENABLE_PROGRAM_MODULAR_TOPIC == 'undefined') ? false : app.appData.configs.ENABLE_PROGRAM_MODULAR_TOPIC;
        for(let i in speaker.products)
        {
            productNames.push(speaker.products[i].name);
        }

        speaker.productNames = productNames.join(', ');
        speaker.show_speaker_confirmation_badge = app.appData.layout.meeting.show_speaker_confirmation_badge;
        speaker.show_speaker_detail_button = app.appData.permissions.speakers;
        speaker.show_veeva_vault_doc_number = app.appData.layout.speaker_detail.show_veeva_vault_doc_num;
        speaker.label = app.appData.labels.speaker;
        speaker.companyKey = app.appData.companyKey;
        speaker.show_speaker_location = app.appData.configs.SHOW_SPEAKER_LOCATION ?? false;

        let speakerModules = Object.values(topic_modules).find(item => item.topic_id == speaker.topic_id);
        this.dom = $(twig({ data: speaker_slot_template }).render({
            ...speaker,
            modules: speakerModules.meeting_modules,
            topic_duration: topic_duration,
            is_modular_topic_enabled: is_modular_topic_enabled
        }))

        if (app.appData.companyKey.includes("allergan")) {
            this.showMeetingProductNames()
        }

        this.dom.on('click', '.speaker_calendar_button', (event) => {
            new SpeakerCalendar($(event.target).closest('.speaker_calendar_button').attr('data-speaker_id'));
        })

        this.dom.on('click', '.speaker_details_button', (event) => {
            app.navigate('speaker', [$(event.target).closest('.speaker_details_button').attr('data-speaker_id')])
        })

        this.dom.on('click', '.speaker_meetings_button', (event) => {
            let speaker_meetings = new SpeakerMeetings($(event.target).closest('.speaker_meetings_button').attr('data-speaker_id'), this.meeting_id);
            let modal = new bootstrap.Modal(speaker_meetings.dom);
            modal.show();
        })
    }

    showMeetingProductNames() {
        $.ajax({
            url : "api/meeting/product",
            method : "get",
            data : {
                meeting_id: this.meeting_id
            }
        }).then((data) => {
            if(data.success) {
                $('#meetingProductNames span').text(data.data.map(x => x.name).join(', '))
                $('#meetingProductNames').show()
            } else {
                $('#meetingProductNames').remove()
            }
        })
    }
}
