import { AssignSpeakerSlot } from "./AssignSpeakerSlot";

let template = require("./assignspeakers.twig");

export default class AssignSpeakers {
  constructor(data) {
    this.meeting_data = data;
    this.speaker_slots = [];
    this.availabilities = {};
  }

  render() {
    this.dom = $(twig({ data: template }).render());
    let speakerSlotsContainer = this.dom.find("#assign_speaker_slots").empty();
    this.confirmButton = this.dom.find("#confirm_speakers").prop("disabled", true);

    this.meeting_data.speaker_info.forEach((speaker, idx) => {
      const speakerSlot = new AssignSpeakerSlot(
        this.meeting_data,
        speaker,
        this.meeting_data.topic_module_info,
        idx
      );
      speakerSlotsContainer.append(speakerSlot.dom);
      this.speaker_slots.push(speakerSlot);
    });

    this.storePreselectedValues();
    this.validateSpeakers();

    this.dom.on("speakerUpdated", () => {
      let isValid = this.checkDuplicateAndAvailability();
      if (isValid) {
        this.validateSpeakers();
      }
    });

    this.dom.find("#assign_speakers_form").on("submit", (event) => {
      event.preventDefault();
      let request_data = this.getSpeakerData();
      let meetingId = this.meeting_data.logistics_info.meeting_id;

      $.ajax({
        url: `api/meeting/${meetingId}/assignspeakers`,
        method: 'post',
        data: request_data,
      })
        .done((response) => {
          if (response.success) {
            this.modal.hide();
            displayNotification({
              message: response.message,
              success: true,
            });
            app.navigate("meeting", [meetingId]);
          } else {
            displayNotification({
              message: response.message || "An error occurred while assigning speakers.",
              success: false,
            });
          }
        })
        .fail((error) => {
          displayNotification({
            message: "Failed to assign speakers: " + error,
            success: false,
          });
        });
    });

    this.dom.on("hidden.bs.modal", () => this.modal.hide());

    this.modal = new bootstrap.Modal(this.dom[0], {
      backdrop: "static",
      keyboard: false,
      show: false,
      focus: false,
    });

    return this;
  }

  checkDuplicateAndAvailability() {
    let speakertopics = {};
    let meetingDate = moment(this.meeting_data.logistics_info.meeting_date).format("MM/DD/YYYY");
    let hasDuplicates = false;
    let hasUnavailable = false;

    const warningDuplicate = this.dom.find(".warning-duplicate");
    const warningUnavailable = this.dom.find(".warning-unavailable");

    this.speaker_slots.forEach((slot) => {
      let topicId = slot.topic_id.val();
      if (!speakertopics[topicId]) {
        speakertopics[topicId] = new Set();
      }

      slot.dom.find(".speaker_dd").each((i, speaker_dd) => {
        let speakerId = speaker_dd.value;
        if (!speakerId) return;

        // Check for duplicate speakers in the same topic
        if (speakertopics[topicId].has(speakerId)) {
          hasDuplicates = true;
        } else {
          speakertopics[topicId].add(speakerId);
        }

        // Check speaker availability (only for the first speaker per topic)
        if (i === 0 && speakerId) {
          let searchParams = JSON.stringify({ speaker_id: speakerId, meeting_date: meetingDate });
          if (this.availabilities[searchParams] === false) {
            hasUnavailable = true;
          } else if (!(searchParams in this.availabilities)) {
            $.ajax({
              url: "api/meetingrequest/speakeravailability",
              method: "GET",
              async: false,
              data: { speaker_id: speakerId, meeting_date: meetingDate },
            }).done((data) => {
              this.availabilities[searchParams] = data.success;
              if (!data.success) {
                hasUnavailable = true;
              }
            });
          }
        }
      });
    });

    // Show/hide warnings
    hasDuplicates ? warningDuplicate.show(200) : warningDuplicate.hide(200);
    hasUnavailable ? warningUnavailable.show(200) : warningUnavailable.hide(200);

    // Return true if there are no duplicates and all speakers are available
    return !hasDuplicates && !hasUnavailable;
  }

  storePreselectedValues() {
    this.speaker_slots.forEach((slot) => {
      slot.dom.find(".speaker_dd").each((_, dropdown) => {
        let $dropdown = $(dropdown);
        $dropdown.data("preselected", $dropdown.val());
      });
    });
  }

  validateSpeakers() {
    let allValid = true;

    this.speaker_slots.forEach((slot) => {
        slot.dom.find(".speaker_dd").each((_, dropdown) => {
            let $dropdown = $(dropdown);
            let selectedValue = $dropdown.val();
            let preselectedValue = $dropdown.data("preselected");

            if (!selectedValue || selectedValue === preselectedValue) {
                allValid = false;
                $dropdown.addClass("is-invalid");
            } else {
                $dropdown.removeClass("is-invalid");
            }
        });
    });

    this.confirmButton.prop("disabled", !allValid);
  }

  getSpeakerData() {
    let data = [];
    Object.entries(this.speaker_slots).forEach((entry) => {
      let slot = entry[1];
      data.push(slot.getData());
    });

    return {speakers: data};
  }
}
